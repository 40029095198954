import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from '@reach/router';
import {Helmet} from 'react-helmet';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import SubscriptionContent from 'modules/subscription/test';

/**
 * Remove this page when testing daily subscription is done
 *
 */
const Subscription = () => {
  const auth = useSelector((state) => state.auth);
  const [shouldRenderPage, setShouldRenderPage] = React.useState(false);

  // Load subscription page form if tap scripts are loaded
  const handleScriptInject = ({scriptTags}) => {
    if (scriptTags) {
      const scriptTag = scriptTags[1];
      scriptTag.onload = () => {
        setShouldRenderPage(true);
      };
    }
  };
  if (!auth?.authenticated) {
    return <Redirect noThrow to="/auth/login" />;
  }
  return (
    <>
      <Helmet
        onChangeClientState={(_, addedTags) => handleScriptInject(addedTags)}
      >
        <meta name="referrer" content="origin" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js"></script>
        <script src="https://secure.gosell.io/js/sdk/tap.min.js"></script>
      </Helmet>
      {shouldRenderPage ? <SubscriptionContent /> : <LoadingIndicator />}
    </>
  );
};

export default Subscription;
