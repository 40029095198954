import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from '@reach/router';
import {navigate} from 'gatsby';
import {useTap} from './hooks/useTap';
import axios from 'axios';
import notification from 'core/libs/helpers/notification';
import SubHeader from './components/Header';
import StepHeader from './components/StepHeader';
import SubscriptionPackages from './components/SubscriptionPackages';
import Container from './components/common/index';
import {
  PaymentWrapper,
  DescriptionWrapper,
  ButtonWrapper,
  Button,
  CouponWrapper,
  ButtonCoupon,
  SubmitWrapper,
  Loader,
  Line,
} from './styles';
import ChosenPlan from './components/ChosenPlan';
import PaymentMethods from './components/PaymentMethods';
import PayPalButton from './components/PaymentMethods/Paypal/Button';
import AfterPayement from './components/AfterPayement';
import SubscriptionDescription from './components/SubscriptionDescription';
import {useQuery} from '@apollo/client';
import SUBSCRIPTION from './graphql/subscription.graphql';
import RadioBox from './components/SubscriptionPackages/RadioBox';
import LoadingIndicator from 'modules/common/LoadingIndicator';

const isBrowser = typeof window !== 'undefined';
/**
 * Remove this page when testing daily subscription is done
 *
 */
export default () => {
  // Read the search parameters from the URL
  const parameters = new URLSearchParams(window.location.search);

  const {data, loading} = useQuery(SUBSCRIPTION);
  const authAndUser = useSelector((state) => state);
  const isLoaded = typeof window.Tapjsli === 'function';
  const {card, isCardError, handleOnLoad, tap} = useTap();
  const [selectedPlan, setSelectedPlan] = useState('DAILY');
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [currency, setCurrency] = useState('USD');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('TAP');
  const [paymentInfo, setPaymentInfo] = useState(null);

  const usedPaymentMethod = data?.recurrentPayment?.paymentMethod;
  const isActiveSubscription = data?.recurrentPayment?.status === 'ACTIVE';
  const isCanceledSubscription = data?.recurrentPayment?.status === 'CANCELED';
  const isChangingPlan = parameters.get('change-subscription-plan') === 'true';

  const apiKey = authAndUser?.auth?.key;

  const isSubmitButtonDisabled =
    !paymentInfo || paymentInfo === 'newPaymentInfo'
      ? submitStatus === 'loading' || isCardError
      : submitStatus === 'loading' || !paymentInfo;

  const createSubscription = async (headers) => {
    try {
      const createSub = await axios.post(
        `${process.env.GATSBY_API2}api/v1/billing/subscription/create-subscription/?apikey=${apiKey}`,
        {
          plan_name: selectedPlan,
          payment_method: 'TAP',
        },
        headers,
      );

      const activateSub = axios.post(
        `${process.env.GATSBY_API2}api/v1/billing/payment/${createSub?.data?.id}/pay-tap-subscription/?apikey=${apiKey}`,
        headers,
      );

      navigate('/subscription/message', {
        state: {
          fromLink: window.history.state?.fromLink,
        },
      });
    } catch (error) {
      notification({id: 'server_error_try_again'});
      setSubmitStatus('idle');
    }
  };

  const handleSubmit = () => {
    if (isBrowser) {
      setSubmitStatus('loading');
      const headers = {
        'Content-Type': 'application/json',
      };
      if (paymentInfo !== 'oldPaymentInfo') {
        tap.createToken(card).then(async (result) => {
          if (result.error) {
            notification({message: result.error});
          } else {
            try {
              const res = await axios.post(
                `${process.env.GATSBY_API2}api/v1/billing/payment/create-tap-information/?apikey=${apiKey}`,
                {
                  user_id: authAndUser?.user?.profile?.data?.id,
                  tap_card_token: result.id,
                },
                headers,
              );
              createSubscription(headers);
            } catch (error) {
              notification({id: 'server_error_try_again'});
            }
          }
        });
      } else {
        createSubscription(headers);
      }
    }
  };
  useEffect(() => {
    if (submitStatus === 'idle' && isLoaded) {
      handleOnLoad();
    }
  }, [submitStatus, isLoaded]);

  return (
    <>
      <SubHeader />
      {loading ? (
        <LoadingIndicator />
      ) : !isActiveSubscription || isChangingPlan ? (
        <StepHeader step="1" message_id="please_choose_subcription_pack" />
      ) : null}
      {loading ? (
        <LoadingIndicator />
      ) : !isActiveSubscription || isChangingPlan ? (
        <SubscriptionPackages
          radioState={[selectedPlan, setSelectedPlan]}
          currency={currency}
          isActiveSubscription={isActiveSubscription}
        />
      ) : null}
      <StepHeader
        step={!isActiveSubscription || isChangingPlan ? '2' : '1'}
        message_id="choose_payment_method"
      />
      {usedPaymentMethod && isActiveSubscription && (
        <Container className="flex flex-column flex-row-ns items-center pt4">
          <RadioBox
            header="طريقة الدفع الحالية"
            usedPaymentMethod={usedPaymentMethod}
            methodName="oldPaymentInfo"
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            oldPaymentMethod
          />
          <RadioBox
            header="طريقة دفع جديدة"
            subHeader="أضف طريقة مختلفة لشراء خطتك"
            methodName="newPaymentInfo"
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            oldPaymentMethod
          />
        </Container>
      )}
      <Container>
        {(!isActiveSubscription || paymentInfo === 'newPaymentInfo') && (
          <PaymentWrapper>
            <PaymentMethods
              setCurrency={setCurrency}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          </PaymentWrapper>
        )}
        <Line />
        <DescriptionWrapper>
          <ChosenPlan plan={selectedPlan} currency={currency} />
          <SubscriptionDescription />
        </DescriptionWrapper>
        <SubmitWrapper>
          <CouponWrapper>
            {/* <h4>رمز الكوبون</h4>
                <div>
                  <input placeholder="أدخل رمز الكوبون (اختياري)" />
                  <ButtonCoupon>طبق</ButtonCoupon>
                </div> */}
          </CouponWrapper>
          <ButtonWrapper>
            {selectedPaymentMethod === 'PAYPAL' ||
            (paymentInfo === 'oldPaymentInfo' &&
              usedPaymentMethod.name === 'PAYPAL') ? (
              <PayPalButton
                selectedPlan={selectedPlan}
                statusState={[submitStatus, setSubmitStatus]}
              />
            ) : (
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled}
              >
                {submitStatus === 'loading' ? (
                  <Loader />
                ) : (
                  <span>{`${
                    isActiveSubscription || isCanceledSubscription
                      ? 'إنهاء العملية'
                      : 'ابدأ فترتك التجرييبة'
                  }`}</span>
                )}
              </Button>
            )}
          </ButtonWrapper>
        </SubmitWrapper>
      </Container>
    </>
  );
};
